// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'event_name',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'event_date',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'same_user_redemption_limit',
    Object: 'value',
    label: '',
    width: '200'
  }, {
    prop: 'voucher_type',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'applied_target_type',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'code_generation',
    label: '',
    Object: 'value',
    width: '140'
  }, {
    prop: 'online',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'redeem_status',
    label: '',
    Object: 'value',
    width: '150'
  }]
}
